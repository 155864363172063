<template>
  <div class="vCheckbox">
    <label
      v-if="positionLabel === 'start'"
      class="vCheckbox__label"
      :for="id"
      @click="clickHandler"
    >{{ label }}</label>
    <div class="vCheckbox__container">
      <input
        :id="id"
        type="checkbox"
        class="vCheckbox__input"
        :checked="value"
        @change="onChange"
      >
      <div
        :class="{ vCheckbox__customFill: fill }"
        class="vCheckbox__custom"
        :style="{
          width: size + 'px',
          height: size + 'px',
          marginRight: checkboxMarginRight + 'px',
        }"
      />
    </div>
    <label
      v-if="positionLabel === 'end'"
      class="vCheckbox__label"
      :for="id"
      @click="clickHandler"
    >{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: 'VCheckbox',
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    positionLabel: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
    size: {
      type: Number,
      required: false,
      default: 14,
    },
    fill: {
      type: Boolean,
      required: false,
      default: false,
    },
    checkboxMarginRight: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  emits: ['update:modelValue', 'clickHandler'],
  data() {
    return {
      checkData: false,
    }
  },
  mounted() {
    this.checkData = this.value
  },
  methods: {
    clickHandler() {
      this.$emit('clickHandler')
    },
    onChange() {
      this.checkData = !this.checkData
      this.$emit('update:modelValue', this.checkData)
    },
  },
}
</script>

<style lang="scss" scoped>
.vCheckbox {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  &__container {
    position: relative;
  }
  &__input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
    &:checked + .vCheckbox__custom {
      background-image: url('~@/assets/images/checkbox.svg');
    }

    &:checked + .vCheckbox__customFill {
      border-color: #6764ff;
      background: url('~@/assets/images/checkbox-new.svg') no-repeat center
        #6764ff;
      background-size: contain;
    }
  }
  &__custom {
    border: 1px solid #dcdee3;
    border-radius: 4px;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
  }
  &__label {
    cursor: pointer;
  }
}
</style>
